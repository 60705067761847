<template>
  <div>
    <div class="grid crud-demo">
      <h6 class="">Metas</h6>
      <Toast/>

      <div class="col-12">
        <div class="card widget-boxes  p-0 grid grid-nogutter">

          <div class="col-12 md:col-6 lg:col-6 py-3 px-6 border-none md:border-right-1 border-solid">
            <div class="mb-3">
              <Avatar icon="pi pi-folder-open" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Em Aberto</span>
            </div>
            <span class="block font-bold text-2xl mb-3">R$ {{ formatDecimal(valorAberto) }}</span>
            <ProgressBar :value="50" :showValue="false" class="mb-3"></ProgressBar>
          </div>


          <div class="col-12 md:col-6 lg:col-6 py-3 px-6">
            <div class="mb-3">
              <Avatar icon="pi pi-calendar-minus" size="large" shape="circle" class="text-base font-bold"
                      :style="{'background-color':'rgba(94, 208, 250, 0.1)', 'color': '#1992D4', 'border': '1px solid #5ED0FA'}"></Avatar>
              <span class="text-xl ml-2">Realizado</span>
            </div>
            <span class="block font-bold text-3xl mb-3">R$ {{ formatDecimal(valorRealizado) }}</span>
            <ProgressBar :value="30" :showValue="false" class="mb-3"></ProgressBar>
          </div>
        </div>
      </div>


      <div class="col-12">
        <div class="card">


          <DataTable ref="dt" :value="metas" v-model:selection="selectedMetas" dataKey="id" :paginator="true" :rows="10"
                     v-model:filters="filters1" filterDisplay="menu" :loading="loading1" :filters="filters1"
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords} metas" responsiveLayout="scroll">
            <template #header>
              <div class="flex justify-content-between flex-column sm:flex-row">
                <Button type="button" icon="pi pi-filter-slash" label="Limpar Filtros" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                <span class="p-input-icon-left mb-2">
                    <i class="pi pi-search" />
                    <InputText v-model="filters1['global'].value" placeholder="Pesquisar..." style="width: 100%"/>
                  </span>
              </div>
            </template>


            <Column field="codexterno" header="Código" :sortable="true" filterField="codexterno" :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" style="min-width:14rem" :showFilterMenu="false">
              <template #body="slotProps">
                <span class="p-column-title">Código</span>
                {{slotProps.data.codexterno}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo código"/>
              </template>
            </Column>

            <Column field="repres" header="Representante" :sortable="false" :showFilterMenu="false">
              <template #body="slotProps">
                <span class="p-column-title">Representante</span>
                {{slotProps.data.repres}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo representante"/>
              </template>
            </Column>

            <Column field="tipo" header="Tipo" :sortable="false" :showFilterMenu="false">
              <template #body="slotProps">
                <span class="p-column-title">Tipo</span>
                {{slotProps.data.tipo}}
              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo tipo"/>
              </template>
            </Column>


            <Column field="status" header="Status" :sortable="false" :showFilterMenu="false">
              <template #body="slotProps">
                <span class="p-column-title">Status</span>
                <span :class="'meta-badge status-' + (slotProps.data.status ? slotProps.data.status.toLowerCase() : '')">{{slotProps.data.status}}</span>
              </template>
            </Column>

            <Column header="Início" filterField="inicio" dataType="date" style="min-width:10rem" :sortable="false" :showFilterMenu="false">
              <template #body="slotProps">
                {{formatDate(slotProps.data.inicio)}}
              </template>
              <template #filter="{filterModel}">
                <Calendar v-model="filterModel.inicio" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
              </template>
            </Column>

            <Column header="Fim" filterField="fim" dataType="date" style="min-width:10rem" :sortable="false" :showFilterMenu="false">
              <template #body="slotProps">
                {{formatDate(slotProps.data.fim)}}
              </template>
              <template #filter="{filterModel}">
                <Calendar v-model="filterModel.fim" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" />
              </template>
            </Column>

            <Column field="meta" header="Meta" :sortable="true" class="text-right" :showFilterMenu="false">
              <template #body="slotProps">
                <span class="p-column-title">Meta</span>
                {{formatDecimal(slotProps.data.meta)}}
              </template>
              <template #filter="{filterModel}">
                <InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pt-BR" />
              </template>
            </Column>

            <Column field="realizado" header="Realizado" :sortable="true" class="text-right" :showFilterMenu="false">
              <template #body="slotProps">
                <span class="p-column-title">Realizado</span>
                {{formatDecimal(slotProps.data.realizado)}}
              </template>
              <template #filter="{filterModel}">
                <InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pt-BR" />
              </template>
            </Column>


            <Column header="Ações" class="text-right">
              <template #body="slotProps">
                <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mr-2" @click="confirmDeleteMeta(slotProps.data)" />
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editMeta(slotProps.data)" />
                <!--                <Button icon="pi pi-sort-amount-up" class="p-button-rounded p-button-help mr-2" @click="visibleTimeline = true" />-->
              </template>
            </Column>
          </DataTable>




          <Dialog v-model:visible="metaDialog" :style="{width: '450px'}" header="Detalhes da Meta" :modal="true" class="p-fluid">
            <!--          <img :src="'demo/images/meta/' + meta.image" :alt="meta.image" v-if="meta.image" width="150" class="mt-0 mx-auto mb-5 block shadow-2" />-->
            <div class="field">
              <label for="name">Título</label>
              <InputText id="name" v-model.trim="meta.titulo" required="true" autofocus :class="{'p-invalid': submitted && !meta.titulo}" />
              <small class="p-invalid" v-if="submitted && !meta.titulo">Meta é obrigatório.</small>
            </div>
            <div class="field">
              <label for="description">Descrição</label>
              <Textarea id="description" v-model="meta.descricao" required="true" rows="3" cols="20" />
            </div>

            <div class="field">
              <label for="inventoryStatus" class="mb-3">Status</label>
              <Dropdown id="inventoryStatus" v-model="meta.inventoryStatus" :options="statuses" optionLabel="label" placeholder="Selecione o tipo">
                <template #value="slotProps">
                  <div v-if="slotProps.value && slotProps.value.tipo">
                    <span :class="'meta-badge status-' +slotProps.value.tipo">{{slotProps.value.tipo}}</span>
                  </div>
                  <div v-else-if="slotProps.value && !slotProps.value.tipo">
                    <span :class="'meta-badge status-' +slotProps.tipo.toLowerCase()">{{slotProps.tipo}}</span>
                  </div>
                  <span v-else>
									{{slotProps.placeholder}}
								</span>
                </template>
              </Dropdown>
            </div>

            <div class="field">
              <label class="mb-3">Setor</label>
              <div class="formgrid grid">
                <div class="field-radiobutton col-6">
                  <RadioButton id="category1" name="category" value="Accessories" v-model="meta.category" />
                  <label for="category1">Engenharia</label>
                </div>
                <div class="field-radiobutton col-6">
                  <RadioButton id="category2" name="category" value="Clothing" v-model="meta.category" />
                  <label for="category2">Projetos</label>
                </div>
                <div class="field-radiobutton col-6">
                  <RadioButton id="category3" name="category" value="Electronics" v-model="meta.category" />
                  <label for="category3">Marketing</label>
                </div>

              </div>
            </div>

            <div class="formgrid grid">
              <div class="field col">
                <label for="price">Valor</label>
                <InputNumber id="price" v-model="meta.balance" mode="currency" currency="BRL" locale="pt-BR" />
              </div>

            </div>
            <template #footer>
              <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
              <Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="saveMeta" />
            </template>
          </Dialog>

          <Dialog v-model:visible="deleteMetaDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="flex align-items-center justify-content-center">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="meta">Confirma a exclusão de <b>{{meta.titulo}}</b>?</span>
            </div>
            <template #footer>
              <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteMetaDialog = false"/>
              <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteMeta" />
            </template>
          </Dialog>

          <Dialog v-model:visible="deleteMetasDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
            <div class="flex align-items-center justify-content-center">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
              <span v-if="meta">Tem certeza que deseja deletar os itens selecionados?</span>
            </div>
            <template #footer>
              <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteMetasDialog = false"/>
              <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedMetas" />
            </template>
          </Dialog>
        </div>
      </div>


    </div>
    <div class="grid p-fluid">
      <div class="col-12 lg:col-6">
        <div class="card flex flex-column align-items-center">
          <h6>Mês Atual</h6>
          <Chart type="doughnut" :data="pieData" :options="pieOptions" style="position:relative; width: 50%"></Chart>
        </div>
      </div>
      <div class="col-12 lg:col-6">
        <div class="card">
          <h6>Últimos Meses</h6>
          <Chart type="line" :data="lineData" :options="lineOptions"></Chart>
        </div>
      </div>
    </div>


  </div>

</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import MetaService from '@/service/MetaService';
import moment from 'moment'



export default {
  components: {


  },
  data() {
    return {
      valorAberto: 0,
      valorRealizado: 0,
      valorVencido: 0,
      visibleTimeline: false,
      loading1: true,
      filters1: null,
      metas: null,
      metaDialog: false,
      deleteMetaDialog: false,
      deleteMetasDialog: false,
      meta: {},
      selectedMetas: null,
      filters: {},
      submitted: false,
      statuses: [
        {label: 'RECEITA', value: 'receita'},
        {label: 'DESPESA', value: 'despesa'},
      ],

      categorias: [
        {name: "Rural" },
        {name: "Residencial" },
        {name: "Grandes Negócios" },
      ],


      pieData: [],
      pieOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        }
      },


      lineData: [],
      lineOptions: [],

    }
  },
  metaService: null,
  created() {
    this.metaService = new MetaService();
    this.initFilters();
  },
  mounted() {


    this.metaService.getMetas().then((data) => {
      console.log('metasList mounted')
      this.loading1 = false
      this.metas = data[0]
      this.metas.forEach(meta => {
        meta.meta = parseFloat(meta.meta)
        meta.realizado = parseFloat(meta.realizado)
        meta.inicio = moment(meta.inicio).toDate()
        meta.fim = moment(meta.fim).toDate()

        if (meta.status === 'A'){
          this.valorAberto = this.valorAberto + parseFloat(meta.meta)
          var dataVencida = moment(meta.vencimento).isBefore(moment.now());
          // console.log('meta.vencimento')
          // console.log(meta.vencimento)
          // console.log(dataVencida)
          if (dataVencida){
            this.valorVencido = this.valorVencido + parseFloat(meta.meta)
          }
        }else{
          this.valorRealizado = this.valorRealizado + parseFloat(meta.meta)
        }
      });


      this.pieData =  {
        labels: ['Em Aberto', 'Realizado'],
        datasets: [
          {
            data: [this.valorAberto, this.valorRealizado],
            backgroundColor: [
              'rgb(54, 162, 235)',
              'rgb(255, 99, 132)',
              'rgb(255, 205, 86)',
              'rgb(75, 192, 192)'
            ]
          }]
      }

      let labelsLinesChart = data[1]
      let valuesLines1Chart = data[2]
      let valuesLines2Chart = data[3]

      this.lineData = {
        labels: labelsLinesChart,
        datasets: [
          {
            label: 'Em Aberto',
            data: valuesLines1Chart,
            fill: false,
            backgroundColor: 'rgb(255, 205, 86)',
            borderColor: 'rgb(255, 205, 86)',
            tension: .4
          },
          {
            label: 'Realizado',
            data: valuesLines2Chart,
            fill: false,
            backgroundColor: 'rgb(75, 192, 192)',
            borderColor: 'rgb(75, 192, 192)',
            tension: .4
          }
        ]
      }

      this.lineOptions = {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
        }
      }


    })


  },
  methods: {
    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return;
    },
    formatCurrency(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return;
    },
    openNew() {
      this.meta = {};
      this.submitted = false;
      this.metaDialog = true;
    },
    hideDialog() {
      this.metaDialog = false;
      this.submitted = false;
    },
    saveMeta() {
      this.submitted = true;
      if (this.meta.titulo.trim()) {
        if (this.meta.id) {
          this.meta.tipo = this.meta.tipo.value ? this.meta.tipo.value: this.meta.tipo;
          this.metas[this.findIndexById(this.meta.id)] = this.meta;
          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Meta Atualizada', life: 3000});
        }
        else {
          this.meta.id = this.createId();
          this.meta.code = this.createId();
          this.meta.image = 'meta-placeholder.svg';
          this.meta.tipo = this.meta.tipo ? this.meta.tipo.value : 'ENGENHARIA';
          this.metas.push(this.meta);
          this.$toast.add({severity:'success', summary: 'Successo', detail: 'Meta Criada', life: 3000});
        }
        this.metaDialog = false;
        this.meta = {};
      }
    },
    editMeta(meta) {
      this.meta = {...meta};
      this.metaDialog = true;
      // this.$router.push({ path: `/meta/${meta.id}` });
    },
    confirmDeleteMeta(meta) {
      this.meta = meta;
      this.deleteMetaDialog = true;
    },
    deleteMeta() {
      this.metas = this.metas.filter(val => val.id !== this.meta.id);
      this.deleteMetaDialog = false;
      this.meta = {};
      this.$toast.add({severity:'success', summary: 'Successo', detail: 'Meta Deletado', life: 3000});
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.metas.length; i++) {
        if (this.metas[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for ( var i = 0; i < 5; i++ ) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteMetasDialog = true;
    },
    deleteSelectedMetas() {
      this.metas = this.metas.filter(val => !this.selectedMetas.includes(val));
      this.deleteMetasDialog = false;
      this.selectedMetas = null;
      this.$toast.add({severity:'success', summary: 'Successo', detail: 'Metas Deletadas', life: 3000});
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }

      this.filters1 = {
        'code': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},


        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'country.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'representative': {value: null, matchMode: FilterMatchMode.IN},
        'category': {value: null, matchMode: FilterMatchMode.IN},
        'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
        'price': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        'status': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        'activity': {value: null, matchMode: FilterMatchMode.BETWEEN},
        'verified': {value: null, matchMode: FilterMatchMode.EQUALS}
      }

    },

    clearFilter1() {
      this.initFilters();
    },

    formatDate(value) {
      return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';
</style>
